import React from 'react';
import _ from 'lodash';
import styles from './grid.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { AutoPlayVideo } from '../../components/autoplay-video/autoplay-video';

import ActionCallbacks from '../../containers/action-callbacks/action-callbacks';
import OpenFullscreenModalShare from '../../containers/open-fullscreen-modal-share/open-fullscreen-modal-share';
import { WidgetPerformanceLoggers } from '../../containers/performance-loggers/performance-loggers';
import PaymentEvents from '../../components/payment-events/payment-events';
import LiveStartHandler from '../../components/live-start-handler/live-start-handler';
import WithPreviousVideos from './containers/with-previous-videos/with-previous-videos';
import Player from '../../containers/player/player';
import ShareOverlay from '../../containers/share-overlay/share-overlay';
import { withPlayerModuleLoader } from '../../data-components/player-module-loader';
import { ViewModeObserver } from '../../containers/view-mode-observer';
import { getMainVideoId } from '@wix/wix-vod-shared/widget';
import {
  memoizedPartial,
  getChannelForWidget,
} from '@wix/wix-vod-shared/common';
import { CSSGrid, VideoAspectRatio } from '@wix/wix-vod-shared/components';
import { getThumbnailMinWidthAttribute } from '../../components/thumbnail';

/* selectors */
import { isVideoPlayingOptimistic } from '../../selectors/video-playback-status';
import {
  getIsFetching,
  getCursor,
} from '../../redux/lazy-channel-videos/selectors';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import { getMainVideo } from '../../selectors/get-video';
import { showAutoPlay, isPlayerActive } from '../../selectors/layout';
import { getCategory } from '../../selectors/search';
import {
  getNumberOfRows,
  getStretchToFullWidth,
  getContainerMargins,
  isPlayInFrame,
  getThumbnailSpacing,
  getVideosInRowCount,
} from '../../selectors/app-settings';
import { isMobile } from '../../selectors/form-factor';
import {
  getThumbnailWidth,
  getMaxVideosCountInRow,
  getRowSpacing,
} from './selectors';

/* utils */
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from '../../redux/actions/full-screen-modal';
import { selectVideo } from '../../redux/actions/select-video';
import { loadMoreVideoPages } from '../../redux/lazy-channel-videos/actions';
import { requestPlayVideo } from '../../redux/actions/request-play-video';
import { resetSearch } from '../../redux/actions/search';
import VideoThumbnail from '../../components/video-thumbnail/video-thumbnail';
import ActionBar from '../../components/action-bar/action-bar';
import LoadMoreButton from './components/load-more-button/load-more-button';
import { BallsLoader } from './components/balls-loader/balls-loader';
import NoResults from '../components/no-results/no-results';
import { logWidgetSystem } from '../../worker/actions/bi';
import { logWidgetVidClick } from '../../utils/bi/widget-common-events';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { GRID_MARGINS } from './constants';
import { MAX_WIDGET_WIDTH } from '../../utils/videos-sizes/videos-sizes';
import { VIDEOS_ASPECT_RATIO } from '../../constants/videos-aspect-ratio';
import { WIDTH_CONSTRAINTS } from '../../constants/thumbnail-sizes';
import { VIEW_MODES } from '@wix/wix-vod-constants/common';
import * as viewModeSelectors from '../../selectors/view-mode';
import { getCompId } from '../../redux/hydrated-data/hydrated-data';
import {
  endPlayInteraction,
  startPlayInteraction,
  withFedopsInteractions,
} from '../../fedops/interactions';
import { withWindowSize } from '../../containers/window-size';

const mapStateToProps = (state, { windowSize }) => {
  return {
    windowSize,
    styleId: getCompId(state),
    isEditorMode: viewModeSelectors.isEditorMode(state),
    channel: getChannelForWidget(state),
    currentSiteUser: getCurrentSiteUser(state),
    mainVideo: getMainVideo(state),
    mainVideoId: getMainVideoId(state),
    isFetching: getIsFetching(state),
    itemWidth: getThumbnailWidth(state, windowSize.width),
    isVideoPlaying: isVideoPlayingOptimistic(state),
    numberOfRows: getNumberOfRows(state),
    videosInRow: getMaxVideosCountInRow(state, windowSize.width),
    gridColumns: getVideosInRowCount(state),
    isFullWidth: getStretchToFullWidth(state),
    containerMargins: getContainerMargins(state),
    rowSpacing: getRowSpacing(state),
    thumbnailSpacing: getThumbnailSpacing(state),
    hasMoreVideos: getCursor(state),
    isPlayInFrame: isPlayInFrame(state),
    selectedCategory: getCategory(state),
    showAutoPlay: showAutoPlay(state),
    isPlayerActive: isPlayerActive(state),
    isMobile: isMobile(state),
  };
};

const mapDispatchToProps = {
  selectVideo,
  loadMoreVideoPages,
  resetSearch,
  requestPlayVideo,
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
  logWidgetSystem,
  logWidgetVidClick,
};

const GridLayoutComponent = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class GridLayout extends React.Component {
      static propTypes = {
        styleId: PropTypes.string.isRequired,
        isEditorMode: PropTypes.bool.isRequired,
        windowSize: PropTypes.object.isRequired,
        channel: PropTypes.object.isRequired,
        videoIds: PropTypes.array.isRequired,
        videoByIds: PropTypes.object.isRequired,
        currentSiteUser: PropTypes.object,
        selectVideo: PropTypes.func.isRequired,
        mainVideo: PropTypes.object,
        mainVideoId: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        itemWidth: PropTypes.number.isRequired,
        numberOfRows: PropTypes.number.isRequired,
        videosInRow: PropTypes.number.isRequired,
        gridColumns: PropTypes.number.isRequired,
        isFullWidth: PropTypes.bool.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        containerMargins: PropTypes.number.isRequired,
        rowSpacing: PropTypes.number.isRequired,
        thumbnailSpacing: PropTypes.number.isRequired,
        hasMoreVideos: PropTypes.string,
        loadMoreVideoPages: PropTypes.func.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        openFullScreenVideoOverlay: PropTypes.func.isRequired,
        closeFullScreenVideoOverlay: PropTypes.func.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        isSearching: PropTypes.bool.isRequired,
        selectedCategory: PropTypes.string,
        resetSearch: PropTypes.func.isRequired,
        showAutoPlay: PropTypes.bool,
        isPlayerActive: PropTypes.bool,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
      };

      constructor(props) {
        super(props);

        this.state = {
          numberOfRows: props.numberOfRows,
        };

        this.forcedWidth = 0;
        this.thumbnailRefs = {};
        this.playerRef = null;
        this.playerContainerRef = null;
      }

      componentDidMount() {
        this.attachPlayerToSelectedThumbnail(this.props.mainVideoId);
      }

      UNSAFE_componentWillReceiveProps({
        numberOfRows,
        isFullWidth,
        mainVideoId,
      }) {
        if (numberOfRows !== this.props.numberOfRows) {
          this.setState({ numberOfRows });
        }

        if (isFullWidth !== this.props.isFullWidth && !isFullWidth) {
          this.forcedWidth = MAX_WIDGET_WIDTH;
        }

        if (mainVideoId !== this.props.mainVideoId) {
          this.attachPlayerToSelectedThumbnail(mainVideoId);
        }
      }

      componentDidUpdate() {
        if (
          this.forcedWidth &&
          this.props.windowSize.width === MAX_WIDGET_WIDTH
        ) {
          this.forcedWidth = 0;
        }
      }

      attachPlayerToSelectedThumbnail(mainVideoId) {
        const thumbnailRef = this.thumbnailRefs[mainVideoId];

        if (!thumbnailRef || !this.playerRef) {
          return;
        }

        if (thumbnailRef.contains(this.playerRef)) {
          return;
        }

        thumbnailRef.appendChild(this.playerRef);
      }

      reset = ({ editMode }) => {
        const { numberOfRows, mainVideoId } = this.props;

        if (this.state.numberOfRows !== numberOfRows) {
          this.setState({ numberOfRows });
        }

        if (
          this.playerContainerRef &&
          this.playerRef &&
          editMode === VIEW_MODES.EDITOR
        ) {
          this.playerContainerRef.appendChild(this.playerRef);
        }

        if (editMode === VIEW_MODES.PREVIEW) {
          this.attachPlayerToSelectedThumbnail(mainVideoId);
        }
      };

      setCurrentVideoFromPayment = ({ itemId } = {}) => {
        if (itemId) {
          this.props.selectVideo(itemId);
        }
      };

      finishFedopsInteraction = () => {
        endPlayInteraction(this.props.interactions, {
          source: this.props.mainVideo.videoSource,
          layout: 'grid',
        });
      };

      startAutoPlayFedopsInteraction = (videoPlayed) => {
        startPlayInteraction(this.props.interactions, {
          source: videoPlayed.videoSource,
          layout: 'grid',
          trigger: 'auto',
        });
      };

      logVideoPlayRequested = (videoItem) => {
        const { channel, logWidgetVidClick, isPlayInFrame, interactions } =
          this.props;

        if (isPlayInFrame) {
          startPlayInteraction(interactions, {
            source: videoItem.videoSource,
            layout: 'grid',
            trigger: 'click',
          });
        }

        logWidgetVidClick({ videoItem, channelData: channel });
      };

      saveThumbnailRef = (videoId, ref) => {
        this.thumbnailRefs[videoId] = ref;
        if (videoId === this.props.mainVideoId) {
          this.attachPlayerToSelectedThumbnail(this.props.mainVideoId);
        }
      };

      renderThumbnail = (videoId) => {
        const {
          channel,
          isSearching,
          videoByIds,
          currentSiteUser,
          itemWidth,
          rowSpacing,
          thumbnailSpacing,
        } = this.props;

        const video = videoByIds[videoId];

        if (!video) {
          return <div key={videoId} style={{ width: itemWidth }} />;
        }

        return (
          <div
            style={{
              marginTop: Math.max(rowSpacing - 2, 0),
              marginLeft: thumbnailSpacing,
            }}
            key={videoId}
            ref={memoizedPartial(this.saveThumbnailRef, videoId)}
            className={styles.thumbnail}
          >
            <ActionCallbacks
              channelId={channel.id}
              videoItem={video}
              onPlayRequestedBi={this.logVideoPlayRequested}
            >
              <VideoThumbnail
                videoItem={video}
                isLoading={isSearching}
                dataHook="video-list-thumb-wrapper"
                channelData={channel}
                currentSiteUser={currentSiteUser}
                width={itemWidth}
                isContentFocusable
                breakpoints={[
                  {
                    min: 0,
                    width: itemWidth,
                    height: itemWidth / VIDEOS_ASPECT_RATIO,
                  },
                ]}
              />
            </ActionCallbacks>
          </div>
        );
      };

      renderGridContent() {
        const {
          mainVideo,
          isFetching,
          selectedCategory,
          hasMoreVideos,
          videoIds,
        } = this.props;

        if (
          !isFetching &&
          selectedCategory &&
          !hasMoreVideos &&
          !videoIds.length
        ) {
          return this.renderEmptySearchState(
            this.props.t('widget.categories.no-videos-in-category'),
          );
        }

        return mainVideo
          ? this.renderThumbnailsCSSGrid()
          : this.renderEmptyState();
      }

      getContentAriaLabel() {
        const { videoIds, selectedCategory, hasMoreVideos } = this.props;

        const options = {
          videosCount: videoIds.length,
        };

        const moreVideosAvailable = hasMoreVideos
          ? this.props.t('widget.a11y.grid.more-videos-available')
          : '';

        const ariaLabel = selectedCategory
          ? this.props.t('widget.a11y.grid.videos-for-category', {
              ...options,
              category: selectedCategory,
            })
          : this.props.t('widget.a11y.grid.videos', options);

        return [ariaLabel, moreVideosAvailable].join(' ');
      }

      renderThumbnailsCSSGrid() {
        const { videoIds, videosInRow, rowSpacing, thumbnailSpacing, styleId } =
          this.props;

        const { numberOfRows } = this.state;

        const grid = _.range(0, numberOfRows * videosInRow);
        const gridVideoIds = _(grid)
          .map((value) => `fake-item-${value}`)
          .assign(videoIds)
          .value();

        const gridId = 'grid-layout-items';
        const maxRows = Math.ceil(videoIds.length / videosInRow);
        const rows = _.clamp(numberOfRows, 1, maxRows);

        // TODO: maxItemsPerRow

        return (
          <React.Fragment>
            <div
              data-hook="grid-content"
              tabIndex="0"
              aria-label={this.getContentAriaLabel()}
              className={styles.content}
            >
              <CSSGrid
                styleId={styleId}
                gridId={gridId}
                style={{
                  gridAutoRows: 0,
                  overflowY: 'hidden',
                  marginTop: -rowSpacing,
                  marginLeft: -thumbnailSpacing,
                }}
                rows={rows}
                cols={videosInRow}
                minItemWidth={WIDTH_CONSTRAINTS[0]}
              >
                {_.map(gridVideoIds, this.renderThumbnail)}
              </CSSGrid>
            </div>
          </React.Fragment>
        );
      }

      renderEmptyState() {
        return (
          <div data-hook="grid-empty" className={styles.empty}>
            {!this.props.isFetching && (
              <div className={styles.emptyContent}>
                {this.props.t('widget.this-channel-is-coming-soon')}
              </div>
            )}
          </div>
        );
      }

      renderActions() {
        const { channel } = this.props;

        return (
          <ActionBar
            channelData={channel}
            className={styles['action-bar-container']}
          />
        );
      }

      loadMoreVideos = () => {
        const { loadMoreVideoPages, hasMoreVideos, numberOfRows, isFetching } =
          this.props;

        if (isFetching) {
          return;
        }

        this.props.logWidgetSystem('videoList.loadMore.requested');

        const loadPromise = hasMoreVideos
          ? loadMoreVideoPages()
          : Promise.resolve();

        loadPromise.then(() => {
          this.setState({
            numberOfRows: _.clamp(
              this.state.numberOfRows + numberOfRows,
              1,
              this.getNumberOfRows(),
            ),
          });
          this.props.logWidgetSystem('videoList.loadMore.rendered');
        });
      };

      renderLoadMoreButton() {
        const { isFetching } = this.props;

        return (
          <LoadMoreButton
            dataHook="load-more-button"
            isLoading={isFetching}
            onClick={this.loadMoreVideos}
            ariaLabel={this.props.t('widget.load-more')}
          >
            {isFetching ? <BallsLoader /> : this.props.t('widget.load-more')}
          </LoadMoreButton>
        );
      }

      shouldDisplayLoadMoreButton() {
        const { hasMoreVideos } = this.props;
        return (
          hasMoreVideos || this.state.numberOfRows < this.getNumberOfRows()
        );
      }

      getNumberOfRows() {
        const { videoIds, videosInRow } = this.props;
        return Math.ceil(videoIds.length / videosInRow);
      }

      saveContainerRef = (ref) => {
        this.containerRef = ref;
      };

      playVideo = ({ id }) => {
        const {
          channel,
          requestPlayVideo,
          openFullScreenVideoOverlay,
          closeFullScreenVideoOverlay,
          isPlayInFrame,
          isPortableDevice,
        } = this.props;

        if (isPlayInFrame || isPortableDevice) {
          requestPlayVideo(id);
          return;
        }

        openFullScreenVideoOverlay(
          channel.id,
          id,
          true,
          closeFullScreenVideoOverlay,
        );
      };

      renderEmptySearchState(message) {
        return (
          <NoResults
            className={styles.empty}
            dataHook="grid-empty-search-results"
            message={message}
            onButtonClick={this.resetSearch}
          />
        );
      }

      resetSearch = () => {
        const { resetSearch, numberOfRows } = this.props;

        this.setState({ numberOfRows });
        resetSearch();
      };

      savePlayerContainerRef = (ref) => {
        this.playerContainerRef = ref;
      };

      savePlayerRef = (ref) => {
        this.playerRef = ref;
      };

      renderPlayer() {
        const {
          channel,
          mainVideo,
          isPlayerActive,
          showAutoPlay,
          PlayerComponent,
        } = this.props;

        if (!mainVideo) {
          return null;
        }

        return (
          <div
            data-hook="player-wrapper"
            ref={this.savePlayerRef}
            className={classnames(styles.player, {
              [styles.active]: isPlayerActive,
            })}
          >
            <VideoAspectRatio>
              <Player
                PlayerComponent={PlayerComponent}
                fillAllSpace
                onPlayStart={this.finishFedopsInteraction}
              />
              <ShareOverlay
                key={channel.id}
                channelData={channel}
                videoItem={mainVideo}
              />
            </VideoAspectRatio>
            {showAutoPlay && (
              <AutoPlayVideo
                dataHook="autoplay-video"
                onAutoPlayRequested={this.startAutoPlayFedopsInteraction}
              />
            )}
          </div>
        );
      }

      render() {
        const {
          channel,
          isFullWidth,
          containerMargins,
          itemWidth,
          mainVideoId,
          isVideoPlaying,
          isSearching,
          videoIds,
          isPlayInFrame,
        } = this.props;

        const horizontalPadding = isFullWidth ? containerMargins : GRID_MARGINS;

        const containerStyles = {
          padding: `32px ${horizontalPadding}px`,
          minWidth: `${
            this.forcedWidth || itemWidth + horizontalPadding * 2
          }px`,
          minHeight: isSearching || !videoIds.length ? '100vh' : 'unset',
        };

        return (
          <main
            className={styles.container}
            style={containerStyles}
            data-thumbnail-min-width={getThumbnailMinWidthAttribute(itemWidth)}
            data-hook="widget-container"
            data-channel-layout="grid"
            ref={this.saveContainerRef}
            aria-label={this.props.t(
              'widget.accessibility.channel-videos-widget',
              {
                channelTitle: channel.title,
              },
            )}
            tabIndex="0"
          >
            {isPlayInFrame && (
              <div
                ref={this.savePlayerContainerRef}
                style={{ display: 'none' }}
              >
                {this.renderPlayer()}
              </div>
            )}
            {this.renderActions()}
            {this.renderGridContent()}
            {this.shouldDisplayLoadMoreButton() && this.renderLoadMoreButton()}
            <OpenFullscreenModalShare itemWidth={itemWidth} />
            <PaymentEvents
              onRent={this.setCurrentVideoFromPayment}
              onSale={this.setCurrentVideoFromPayment}
            />
            <WidgetPerformanceLoggers />
            <LiveStartHandler
              playVideo={this.playVideo}
              isVideoPlaying={isVideoPlaying}
              selectedVideoId={mainVideoId}
            />
            <ViewModeObserver onChange={this.reset} />
          </main>
        );
      }
    },
  ),
);

export const GridLayout = withFedopsInteractions(
  withWindowSize(GridLayoutComponent),
);
export default withPlayerModuleLoader(WithPreviousVideos(GridLayout));
